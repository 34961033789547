document.addEventListener('DOMContentLoaded', function() {
    // Create the overlay div
    var overlay = document.createElement('div');
    overlay.className = 'overlay';
    document.body.appendChild(overlay);

    // Get all popup wrappers for both flavors and recipes
    var flavorWrappers = document.querySelectorAll('.popup-wrapper');
    var recipeWrappers = document.querySelectorAll('.recipe-wrapper');
    var scrollPosition = 0;

    // Function to calculate scrollbar width
    function getScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    // Function to show the popup and overlay
    function showPopup(popup) {
        scrollPosition = window.pageYOffset;
        var scrollbarWidth = getScrollbarWidth();
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.position = 'fixed';
        document.body.style.width = `calc(100% - ${scrollbarWidth}px)`;
        overlay.style.display = 'flex';
        popup.style.display = 'flex';
        document.body.classList.add('overlay-active');
    }

    // Function to hide the popup and overlay
    function hidePopup() {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        window.scrollTo(0, scrollPosition);
        overlay.style.display = 'none';
        var popups = document.querySelectorAll('.popup-wrap, .recipe-popup');
        popups.forEach(function(popup) {
            popup.style.display = 'none';
        });
        document.body.classList.remove('overlay-active');
    }

    // Add click event listener to each flavor popup wrapper
    flavorWrappers.forEach(function(wrapper) {
        var openButton = wrapper.querySelector('.flavor-popup');
        var popup = wrapper.querySelector('.popup-wrap');
        var closeButton = wrapper.querySelector('.popup-flavor-close');

        openButton.addEventListener('click', function() {
            showPopup(popup);
        });

        // Prevent event propagation to avoid closing the popup when clicking inside it
        popup.addEventListener('click', function(event) {
            event.stopPropagation();
        });

        // Add click event listener to the close button
        closeButton.addEventListener('click', function() {
            hidePopup();
        });
    });

    // Add click event listener to each recipe popup wrapper
    recipeWrappers.forEach(function(wrapper) {
        var openButton = wrapper.querySelector('.recipe-wrap');
        var popup = wrapper.querySelector('.recipe-popup');
        var closeButton = wrapper.querySelector('.popup-recipe-close');

        openButton.addEventListener('click', function() {
            showPopup(popup);
        });

        // Prevent event propagation to avoid closing the popup when clicking inside it
        popup.addEventListener('click', function(event) {
            event.stopPropagation();
        });

        // Add click event listener to the close button
        closeButton.addEventListener('click', function() {
            hidePopup();
        });
    });

    // Add click event listener to the overlay to close the popup
    overlay.addEventListener('click', function(event) {
        hidePopup();
    });
});


//Age Gate
document.addEventListener('DOMContentLoaded', function() {
    // Function to check age
    function checkAge() {
        const monthElement = document.querySelector('#input_1_1_1');
        const dayElement = document.querySelector('#input_1_1_2');
        const yearElement = document.querySelector('#input_1_1_3');

        if (!monthElement || !dayElement || !yearElement) {
            console.error('One or more date input fields are not found');
            return false;
        }

        const month = parseInt(monthElement.value);
        const day = parseInt(dayElement.value);
        const year = parseInt(yearElement.value);

        if (isNaN(month) || isNaN(day) || isNaN(year)) {
            return false;
        }

        const birthdate = new Date(year, month - 1, day);
        const today = new Date();
        let age = today.getFullYear() - birthdate.getFullYear();
        const m = today.getMonth() - birthdate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
            age--;
        }

        return age >= 21;
    }

    function showValidationMessage() {
        const validationMessage = "You must be 21 or older to enter this site.";
        let messageDiv = document.querySelector('.gf-age-validation');

        if (!messageDiv) {
            messageDiv = document.createElement('div');
            messageDiv.classList.add('gf-age-validation');
            messageDiv.style.position = 'absolute';

            const messageContent = document.createElement('div');
            messageContent.classList.add('message-content');
            messageContent.textContent = validationMessage;

            const closeButton = document.createElement('button');
            closeButton.textContent = 'X';
            closeButton.classList.add('close-button');

            closeButton.addEventListener('click', function() {
                messageDiv.style.display = 'none';
            });

            messageDiv.appendChild(messageContent);
            messageDiv.appendChild(closeButton);
            document.querySelector('#gform_wrapper_1').appendChild(messageDiv);
        } else {
            messageDiv.style.display = 'block';
        }
    }

    // Function to set a session storage item
    function setSessionItem(name, value) {
        sessionStorage.setItem(name, value);
    }

    // Function to get a session storage item
    function getSessionItem(name) {
        return sessionStorage.getItem(name);
    }

    // Function to initialize the popup check
    function initializePopupCheck() {
        const popupElement = document.querySelector('#elementor-popup-modal-306');

        if (popupElement) {
            addSubmitListener(popupElement);
            checkSession(popupElement);
        } else {
            console.error('Popup element not found');
        }
    }

    // On form submit, check age
    function addSubmitListener(popupElement) {
        const submitButton = document.querySelector('#gform_submit_button_1');
        if (submitButton) {
            submitButton.addEventListener('click', function(event) {
                event.preventDefault();

                if (checkAge()) {
                    setSessionItem('ageVerified', 'true'); // Set session storage item
                    popupElement.style.display = 'none';
                } else {
                    showValidationMessage();
                }
            });
        } else {
            console.error('Form submit button not found');
        }
    }

    // Check session storage to see if age was already verified
    function checkSession(popupElement) {
        if (getSessionItem('ageVerified') === 'true') {
            popupElement.style.display = 'none';
        } else {
            // Show the popup if age is not verified
            popupElement.style.display = 'block';
        }
    }

    // Initialize popup check immediately if the session is already verified
    if (getSessionItem('ageVerified') === 'true') {
        const style = document.createElement('style');
        style.innerHTML = `
            #elementor-popup-modal-306 {
                display: none !important;
            }
        `;
        document.head.appendChild(style);
    } else {
        // Set an interval to check for the popup element until it is found
        const intervalId = setInterval(function() {
            const popupElement = document.querySelector('#elementor-popup-modal-306');

            if (popupElement) {
                clearInterval(intervalId);
                initializePopupCheck();
            }
        }, 100); // Check every 100ms
    }
});









